$(document).on('turbolinks:load', function() {


    const iconPassword = document.getElementById('togglePassword');
    const iconPasswordConfirmation = document.getElementById('togglePasswordConfirmation');
    
    
    let password = document.getElementById('password');
    let passwordConfirmation = document.getElementById('password-confirmation');
    
  
    
  
  
    // For password
    if (password){
  
      /* Event fired when <i> is clicked */
      iconPassword.addEventListener('click', function() {
        if(password.type === "password") {
          password.type = "text";
          iconPassword.classList.add("fa-eye");
          iconPassword.classList.remove("fa-eye-slash");
        }
        else {
          password.type = "password";
          iconPassword.classList.add("fa-eye-slash");
          iconPassword.classList.remove("fa-eye");
        }
        
      });
  
    }

    // For password confirmation
    if (passwordConfirmation){
  
      /* Event fired when <i> is clicked */
      iconPasswordConfirmation.addEventListener('click', function() {
        if(passwordConfirmation.type === "password") {
          passwordConfirmation.type = "text";
          iconPasswordConfirmation.classList.add("fa-eye");
          iconPasswordConfirmation.classList.remove("fa-eye-slash");
        }
        else {
          passwordConfirmation.type = "password";
          iconPasswordConfirmation.classList.add("fa-eye-slash");
          iconPasswordConfirmation.classList.remove("fa-eye");
        }
        
      });
  
    }
  
   
  
    
  
    
  });
  
  